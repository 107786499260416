import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Forms from './components/Forms';
import Messages from './components/Messages';

// Login overlay component
const LoginOverlay = ({ onLogin, isLoggingOut }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === 'password') {
      setIsAuthenticating(true);
      await new Promise(resolve => setTimeout(resolve, 250));
      onLogin();
    } else {
      setError('Incorrect password');
    }
  };

  return (
    <div className={`
      fixed inset-0 z-50
      transition-all duration-500 ease-in-out
      ${isAuthenticating ? 'backdrop-blur-0 pointer-events-none opacity-0' : 
        isLoggingOut ? 'backdrop-blur-md opacity-100' : 
        'backdrop-blur-md opacity-100'}
      flex items-center justify-center
      bg-black/10
    `}>
      <div className={`
        relative z-10
        bg-white/95 dark:bg-gray-900/95 backdrop-blur-sm p-10
        w-full max-w-md
        transition-all duration-500
        ${isAuthenticating ? 'opacity-0 scale-95 translate-y-4' : 
          isLoggingOut ? 'opacity-100 scale-100 translate-y-0' : 
          'opacity-100 scale-100 translate-y-0'}
        rounded-3xl
        shadow-[0_8px_32px_rgba(0,0,0,0.12)]
        hover:shadow-[0_8px_32px_rgba(0,0,0,0.16)]
        transition-shadow duration-300
      `}>
        <div className="relative z-10 space-y-8">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white text-center">Welcome</h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                className="
                  w-full px-6 py-4 
                  bg-gray-50/50 dark:bg-gray-800/50
                  border-0
                  rounded-2xl
                  text-gray-900 dark:text-white
                  placeholder:text-gray-400 dark:placeholder:text-gray-500
                  focus:ring-2 focus:ring-blue-500/20 focus:outline-none
                  transition-all duration-200
                  text-lg
                "
                required
              />
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-b from-white/80 dark:from-gray-900/80 to-white/0 dark:to-gray-900/0 pointer-events-none"></div>
            </div>
            {error && (
              <p className="text-red-500 text-sm text-center px-6 py-2 bg-red-50/50 dark:bg-red-900/50 rounded-xl">
                {error}
              </p>
            )}
            <button
              type="submit"
              className="
                w-full px-6 py-4
                bg-blue-600 
                hover:bg-blue-700 
                text-white 
                rounded-2xl
                text-lg font-medium
                transition-all duration-200
                hover:shadow-lg hover:shadow-blue-500/20
                transform hover:-translate-y-0.5
                focus:outline-none focus:ring-2 focus:ring-blue-500/20
              "
            >
              Enter
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const AppLayout = ({ isAuthenticated, onLogout, isLoggingOut }) => {
  const handleLogoutWithTransition = async () => {
    onLogout(true);
    await new Promise(resolve => setTimeout(resolve, 500));
    onLogout(false);
  };

  return (
    <div className={`
      flex min-h-screen bg-gray-100 dark:bg-gray-950
      transition-all duration-500 ease-in-out
      ${isAuthenticated && !isLoggingOut ? 'blur-0' : 'blur-sm'}
    `}>
      <Sidebar onLogout={handleLogoutWithTransition} />
      <main className="flex-1 transition-all duration-300 ml-72 flex items-center justify-center p-8">
        <Routes>
          <Route path="/" element={<Navigate to="/forms" replace />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/messages" element={<Messages />} />
        </Routes>
      </main>
    </div>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    const darkMode = localStorage.getItem('isDark') === 'true';
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = (transitioning = false) => {
    if (transitioning) {
      setIsLoggingOut(true);
    } else {
      setIsLoggingOut(false);
      setIsAuthenticated(false);
    }
  };

  return (
    <Router>
      <div className="relative">
        <AppLayout 
          isAuthenticated={isAuthenticated} 
          onLogout={handleLogout}
          isLoggingOut={isLoggingOut}
        />
        {(!isAuthenticated || isLoggingOut) && 
          <LoginOverlay 
            onLogin={handleLogin} 
            isLoggingOut={isLoggingOut}
          />
        }
      </div>
    </Router>
  );
};

export default App;