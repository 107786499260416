import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Search, Filter, SortAsc, SortDesc, Download, FileText, Calendar, Building2, Users, DollarSign, Loader2, ChevronDown, X } from 'lucide-react';

const CustomSelect = ({ value, onChange, options, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={selectRef}>
      <button
        className="w-full px-4 py-2 text-left bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900 dark:text-gray-100"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center justify-between">
          <span className="block truncate">
            {options.find(opt => opt.value === value)?.label || placeholder}
          </span>
          <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
        </div>
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow-lg">
          {options.map((option) => (
            <button
              key={option.value}
              className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-700 first:rounded-t-lg last:rounded-b-lg text-gray-900 dark:text-gray-100"
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const SortDropdown = ({ onSort }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSort = (config) => {
    onSort(config);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="w-full px-4 py-2 bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="flex items-center">
          <SortAsc className="w-4 h-4 mr-2" />
          Sort by
        </span>
        <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 w-56 mt-1 bg-white border rounded-lg shadow-lg">
          {[
            { label: 'Date (Newest)', config: { key: 'submissionDate', direction: 'desc' } },
            { label: 'Date (Oldest)', config: { key: 'submissionDate', direction: 'asc' } },
            { label: 'Amount (Highest)', config: { key: 'requestedAmount', direction: 'desc' } },
            { label: 'Amount (Lowest)', config: { key: 'requestedAmount', direction: 'asc' } },
            { label: 'Business Name (A-Z)', config: { key: 'businessName', direction: 'asc' } }
          ].map((option) => (
            <button
              key={option.label}
              className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 first:rounded-t-lg last:rounded-b-lg"
              onClick={() => handleSort(option.config)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const FormCard = ({ form, onView }) => {
  const metadata = form.metadata || {};

  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch {
      return 'Date not available';
    }
  };

  const formatCurrency = (amount) => {
    try {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(amount);
    } catch {
      return 'Amount not available';
    }
  };

  const formatFileSize = (bytes) => {
    if (!bytes) return 'Size unknown';
    const mb = bytes / 1024 / 1024;
    return `${mb.toFixed(2)} MB`;
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg border dark:border-gray-700 shadow-sm hover:shadow-lg transition-shadow duration-300">
      {/* Header */}
      <div className="p-6 pb-4">
        <div className="flex justify-between items-start">
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white line-clamp-1">
              {metadata.businessName || 'Business Name Not Available'}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
              Submitted {formatDate(metadata.submissionDate)}
            </p>
          </div>
          <button
            onClick={() => onView(form.url)}
            className="p-2 text-gray-400 dark:text-gray-500 hover:text-blue-500 dark:hover:text-blue-400 transition-colors"
          >
            <Download className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="px-6 space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-1">
            <p className="text-sm text-gray-500 dark:text-gray-400 flex items-center">
              <Users className="w-4 h-4 mr-2" />
              Owners
            </p>
            <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
              {metadata.owner1Name || 'N/A'}
              {metadata.owner2Name && metadata.owner2Name !== 'N/A' && (
                <>, {metadata.owner2Name}</>
              )}
            </p>
          </div>
          <div className="space-y-1">
            <p className="text-sm text-gray-500 dark:text-gray-400 flex items-center">
              <DollarSign className="w-4 h-4 mr-2" />
              Amount
            </p>
            <p className="text-sm font-medium text-blue-600 dark:text-blue-400">
              {formatCurrency(metadata.requestedAmount)}
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="px-6 py-4 mt-4 border-t dark:border-gray-700 flex justify-between items-center">
        <span className="text-sm text-gray-500 dark:text-gray-400 flex items-center">
          <FileText className="w-4 h-4 mr-2" />
          {formatFileSize(form.size)}
        </span>
        <button
          onClick={() => onView(form.url)}
          className="text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-300 text-sm font-medium"
        >
          View PDF
        </button>
      </div>
    </div>
  );
};

const FormDashboard = () => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'submissionDate', direction: 'desc' });
  const [filterConfig, setFilterConfig] = useState({
    dateRange: 'all',
    amountRange: 'all'
  });

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    try {
      const response = await fetch('/api/forms/pdfs');
      const data = await response.json();
      if (data.success) {
        setForms(data.files);
      } else {
        setError('Failed to load forms');
      }
    } catch (err) {
      setError('Failed to load forms');
    } finally {
      setLoading(false);
    }
  };

  const filteredAndSortedForms = useMemo(() => {
    let result = [...forms];

    // Apply search
    if (searchTerm) {
      result = result.filter(form => {
        const metadata = form.metadata || {};
        return (
          metadata.businessName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          metadata.owner1Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          metadata.owner2Name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }

    // Apply date filter
    if (filterConfig.dateRange !== 'all') {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
      const ninetyDaysAgo = new Date(now.setDate(now.getDate() - 90));

      result = result.filter(form => {
        const submissionDate = new Date(form.metadata?.submissionDate);
        switch (filterConfig.dateRange) {
          case '30days':
            return submissionDate >= thirtyDaysAgo;
          case '90days':
            return submissionDate >= ninetyDaysAgo;
          default:
            return true;
        }
      });
    }

    // Apply amount filter
    if (filterConfig.amountRange !== 'all') {
      result = result.filter(form => {
        const amount = form.metadata?.requestedAmount;
        switch (filterConfig.amountRange) {
          case 'less50k':
            return amount < 50000;
          case '50k-100k':
            return amount >= 50000 && amount <= 100000;
          case 'more100k':
            return amount > 100000;
          default:
            return true;
        }
      });
    }

    // Apply sorting
    result.sort((a, b) => {
      const aValue = a.metadata?.[sortConfig.key];
      const bValue = b.metadata?.[sortConfig.key];

      if (sortConfig.key === 'submissionDate') {
        return sortConfig.direction === 'asc' 
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      if (sortConfig.key === 'requestedAmount') {
        return sortConfig.direction === 'asc'
          ? aValue - bValue
          : bValue - aValue;
      }

      return sortConfig.direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });

    return result;
  }, [forms, searchTerm, sortConfig, filterConfig]);

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500 mb-4" />
        <p className="text-gray-600 dark:text-gray-400">Loading forms...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="text-red-500 mb-2">⚠️ {error}</div>
        <button
          onClick={fetchForms}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
      {/* Header */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Forms Dashboard</h1>
            <p className="text-gray-500 dark:text-gray-400 mt-1">Manage and track all submitted forms</p>
          </div>
          <span className="px-3 py-1 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 rounded-full text-sm font-medium">
            {filteredAndSortedForms.length} forms
          </span>
        </div>

        {/* Controls */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="md:col-span-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search by business or owner name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          
          <div className="md:col-span-3">
            <CustomSelect
              value={filterConfig.dateRange}
              onChange={(value) => setFilterConfig(prev => ({ ...prev, dateRange: value }))}
              options={[
                { value: 'all', label: 'All time' },
                { value: '30days', label: 'Last 30 days' },
                { value: '90days', label: 'Last 90 days' }
              ]}
              placeholder="Filter by date"
            />
          </div>

          <div className="md:col-span-3">
            <CustomSelect
              value={filterConfig.amountRange}
              onChange={(value) => setFilterConfig(prev => ({ ...prev, amountRange: value }))}
              options={[
                { value: 'all', label: 'All amounts' },
                { value: 'less50k', label: 'Less than $50k' },
                { value: '50k-100k', label: '$50k - $100k' },
                { value: 'more100k', label: 'More than $100k' }
              ]}
              placeholder="Filter by amount"
            />
          </div>

          <div className="md:col-span-2">
            <SortDropdown onSort={setSortConfig} />
          </div>
        </div>
      </div>

      {/* Forms Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredAndSortedForms.map((form) => (
          <FormCard
            key={form.filename || Math.random()}
            form={form}
            onView={(url) => window.open(url, '_blank')}
          />
        ))}
      </div>

      {filteredAndSortedForms.length === 0 && (
        <div className="text-center py-12 bg-gray-50 dark:bg-gray-900 rounded-lg">
          <FileText className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-600" />
          <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">No forms found</h3>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            {searchTerm || filterConfig.dateRange !== 'all' || filterConfig.amountRange !== 'all'
              ? 'Try adjusting your search or filters'
              : 'No forms have been submitted yet'}
          </p>
        </div>
      )}
    </div>
  );
};

export default FormDashboard;